.wrapper {
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &::before {
    transition: 0.4s all;
    color: white;
    font-size: 30px;
    content: '👁';
    opacity: 0;
    background-color: rgba(0, 0, 0);
    position: absolute;
    top: 0;
    border-radius: 5px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 101%;
    width: 101%;
    z-index: 10;
  }

  &:hover {
    &::before {
      opacity: 0.7;
    }
  }
}
