$width: 200px;
$height: 80px;
.input {
  display: none;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;

  height: $height;
  width: $width;
  border-radius: 5px;
}

.enter {
  border: 3px dashed rgb(76, 0, 255);
  box-shadow: 0px 0px 10px 1px rgba(34, 60, 80, 0.29) inset;
}

.leave {
  background-color: #008cff33;
  border: 3px dashed #667a8a;
}

.img {
  border: 3px solid #667a8a;
  border-radius: 5px;
  height: $height;
  width: $height;
  object-fit: cover;
}

.uploadText {
  opacity: 0.5;
}

.paper {
  overflow: hidden;
  &::before {
    content: '';
    height: $height;
    width: $height;
    top: -65px;
  }
}

.reletive {
  position: relative;
  &:hover {
    & .deleteIcon,
    .eyeIcon {
      opacity: 1;
    }
    .paper {
      &::before {
        opacity: 0.7;
      }
    }
    &::after {
      display: block;
      position: absolute;
      width: 2px;
      background-color: white;
      height: 100%;
      left: calc(50% - 2px);
      top: 0;
    }
  }
}

.eyeIcon {
  transition: 0.3s all;
  opacity: 0;
  position: absolute;
  top: 30%;
  left: 13%;
  z-index: 111;
  color: white;
  cursor: pointer;
  &:hover {
    font-size: 28px;
  }
}

.deleteIcon {
  cursor: pointer;
  transition: 0.3s all;
  opacity: 0;
  z-index: 110;
  position: absolute;
  top: calc(50% - 18px);
  right: 10%;
  color: white;
  &:hover {
    font-size: 28px;
  }
}
.imageListWrapper {
  width: 100%;
  height: 90px;
  overflow: hidden;
  overflow-x: scroll;
}
.imageList {
  width: 100%;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
