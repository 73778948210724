.menuItem {
  display: flex;
  gap: 10px;
  padding: 5px 5px;
}

.center {
  justify-content: center;
}

.between {
  justify-content: space-between;
}
