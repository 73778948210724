* {
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  font-family: Roboto, Arial, sans-serif;

  &::before,
  &::after {
    box-sizing: border-box;
  }
} /* Links*/

a {
  text-decoration: none;

  &:link,
  &:visited,
  &:hover {
    text-decoration: none;
  }
} /* Common*/

aside,
nav,
footer,
header,
section,
main {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}
ul {
  list-style: none;

  li {
    list-style: none;
  }
}

address {
  font-style: normal;
}
::-webkit-scrollbar-track {
  background-color: white;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(54, 69, 93, 0.2) !important;
  border-radius: 50px !important;
  border: 3px solid white;
}
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  scroll-behavior: smooth !important;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(54, 69, 93, 0.3) !important;
}
/* Form*/

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}
input::-ms-clear {
  display: none;
}
button {
  display: inline-block;
  box-shadow: none;
  background: none;
  cursor: pointer;
}
input {
  &[type='submit'] {
    display: inline-block;
    box-shadow: none;
    background: none;
    cursor: pointer;
  }
  &:focus,
  &:active {
    outline: none;
  }
}
button {
  &:focus,
  &:active {
    outline: none;
  }
  &::-moz-focus-inner {
    padding: 0;
    border: 0;
  }
}
label {
  cursor: pointer;
}

.CloseIcon {
  cursor: pointer;
}
.MuiDayCalendar-slideTransition {
  min-height: 140px;
}
