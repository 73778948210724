.app {
  height: 100vh;
  max-height: 100vh;
  max-width: 100%;
  overflow: hidden;
}

.loadingPage {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
}

.content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  width: 100%;
}

.contentWrapper {
  position: relative;
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
}
